import {
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box>
      <Typography variant="h3" align="center">
        Privacy Policy
      </Typography>
      <Divider />
      <Typography paragraph sx={{ mt: 2 }}>
        At Jokes Battle, accessible from www.jokesbattle.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by Jokes
        Battle and how we use it.
      </Typography>

      <Typography paragraph>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </Typography>

      <Typography paragraph>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Jokes Battle. This policy is not applicable to
        any information collected offline or via channels other than this
        website.
      </Typography>

      <Typography variant="h4">Consent</Typography>

      <Typography paragraph>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </Typography>

      <Typography variant="h4">Information we collect</Typography>

      <Typography paragraph>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </Typography>
      <Typography paragraph>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </Typography>
      <Typography paragraph>
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </Typography>

      <Typography variant="h4">How we use your information</Typography>

      <Typography paragraph>
        We use the information we collect in various ways, including to:
      </Typography>

      <List sx={{ listStyleType: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Provide, operate, and maintain our website
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Improve, personaze, and expand our website
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Understand and analyze how you use our website
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Develop new products, services, features, and functionaty
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Send you emails</ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>Find and prevent fraud</ListItemText>
        </ListItem>
      </List>

      <Typography variant="h4">Log Files</Typography>

      <Typography paragraph>
        Joke Battles follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </Typography>

      <Typography variant="h4">Cookies and Web Beacons</Typography>

      <Typography paragraph>
        Like any other website, Joke Battle uses "cookies". These cookies are
        used to store information including visitors' preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
      </Typography>

      <Typography variant="h4">Google DoubleClick DART Cookie</Typography>

      <Typography paragraph>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors based upon
        their visit to www.jokesbattle.com and other sites on the internet.
        However, visitors may choose to decline the use of DART cookies by
        visiting the Google ad and content network Privacy Policy at the
        following URL –{" "}
        <Link href="https://policies.google.com/technologies/ads">
          https://policies.google.com/technologies/ads
        </Link>
      </Typography>

      <Typography variant="h4">Our Advertising Partners</Typography>

      <Typography paragraph>
        Some of advertisers on our site may use cookies and web beacons. Our
        advertising partners are listed below. Each of our advertising partners
        has their own Privacy Policy for their policies on user data. For easier
        access, we hyperlinked to their Privacy Policies below.
      </Typography>

      <List>
        <ListItem>
          <ListItemText>
            <Typography paragraph>Google</Typography>
            <Typography paragraph>
              <Link href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </Link>
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      <Typography variant="h4">
        Advertising Partners Privacy Policies
      </Typography>

      <Typography paragraph>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of Joke Battle.
      </Typography>

      <Typography paragraph>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on Joke Battle, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </Typography>

      <Typography paragraph>
        Note that Joke Battle has no access to or control over these cookies
        that are used by third-party advertisers.
      </Typography>

      <Typography variant="h4">Third Party Privacy Policies</Typography>

      <Typography paragraph>
        Joke Battle's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.
      </Typography>

      <Typography paragraph>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </Typography>

      <Typography variant="h4">
        CCPA Privacy Rights (Do Not Sell My Personal Information)
      </Typography>

      <Typography paragraph>
        Under the CCPA, among other rights, California consumers have the right
        to:
      </Typography>
      <Typography paragraph>
        Request that a business that collects a consumer's personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
      </Typography>
      <Typography paragraph>
        Request that a business delete any personal data about the consumer that
        a business has collected.
      </Typography>
      <Typography paragraph>
        Request that a business that sells a consumer's personal data, not sell
        the consumer's personal data.
      </Typography>
      <Typography paragraph>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </Typography>

      <Typography variant="h4">GDPR Data Protection Rights</Typography>

      <Typography paragraph>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </Typography>
      <Typography paragraph>
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
      </Typography>
      <Typography paragraph>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
      </Typography>
      <Typography paragraph>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
      </Typography>
      <Typography paragraph>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
      </Typography>
      <Typography paragraph>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
      </Typography>
      <Typography paragraph>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </Typography>
      <Typography paragraph>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </Typography>

      <Typography variant="h4">Children's Information</Typography>

      <Typography paragraph>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </Typography>

      <Typography paragraph>
        Joke Battle does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </Typography>

      <Typography variant="h4">Changes to This Privacy Policy</Typography>

      <Typography paragraph>
        We may update our Privacy Policy from time to time. Thus, we advise you
        to review this page periodically for any changes. We will notify you of
        any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately, after they are posted on this page.
      </Typography>

      <Typography paragraph>
        Our Privacy Policy was created with the help of the{" "}
        <Link href="https://www.termsfeed.com/privacy-policy-generator/">
          {" "}
          Privacy Policy Generator
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
