import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Battle from "../components/battle/Battle";
import TabFeedHome from "../components/feed/TabFeedHome";
import Navbar from "../components/layout/Navbar";
import AddPost from "../components/post/AddPost";
import PostFeedHome from "../components/post/PostFeedHome";
import Posts from "../components/post/Posts";
import Profile from "../components/profile/Profile";
import PublicProfile from "../components/profile/PublicProfile";
import RequestFeed from "../components/request/RequestFeed";
import PrivateRoute from "../utils/PrivateRoute";
import Account from "./Account";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import SignUp from "./SignUp";
import FAQ from "./static/FAQ";
import PrivacyPolicy from "./static/PrivacyPolicy";
import TermsAndConditions from "./static/TermsAndConditions";

const drawerWidth = 240;

if (window.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:5001/yomama-f9594/us-central1/api";
} else {
  axios.defaults.baseURL =
    "https://us-central1-yomama-f9594.cloudfunctions.net/api";
}

const Home = () => {
  const authenticated = useSelector((state) => state.user.authenticated);
  const errors = useSelector((state) => state.UI.errors);

  const navigate = useNavigate();

  useEffect(() => {
    if (errors && errors.verifyEmail) {
      navigate("/login", { state: { verifyEmail: errors.verifyEmail } });
    }
  }, [errors, navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Grid container spacing={2} direction="row">
          <Grid item md={8} width="100%">
            <Routes>
              <Route exact path="/" element={<TabFeedHome />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/forgot" element={<ForgotPassword />} />
              <Route exact path="/signup" element={<SignUp />} />

              <Route path="/profile">
                <Route
                  index
                  element={
                    <PrivateRoute isAllowed={authenticated}>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route path=":userhandle" element={<PublicProfile />} />
              </Route>
              <Route
                exact
                path="/account"
                element={
                  <PrivateRoute isAllowed={authenticated}>
                    <Account />
                  </PrivateRoute>
                }
              />

              <Route path="/battles">
                <Route index element={<TabFeedHome />} />
                <Route path=":id" element={<Battle />} />
              </Route>

              <Route path="/posts">
                <Route index element={<PostFeedHome />} />
                <Route path=":handle" element={<Posts />} />
                <Route
                  path="add"
                  element={
                    <PrivateRoute isAllowed={authenticated}>
                      <AddPost />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route exact path="/requests" element={<RequestFeed />} />
              <Route exact path="/privacy" element={<PrivacyPolicy />} />
              <Route exact path="/tnc" element={<TermsAndConditions />} />
              <Route exact path="/faq" element={<FAQ />} />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
