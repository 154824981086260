import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTopUsers } from "../../redux/actions/userActions";

const drawerWidth = 240;
const Sidebar = () => {
  const loading = useSelector((state) => state.user.loading);
  const topUsers = useSelector((state) => state.user.topUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTopUsers());
  }, [dispatch]);
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, mt: "30%" }}
      aria-label="tags"
    >
      <Box flex={1} p={2}>
        <List>
          <ListItem disablePadding>
            <ListItemButton component="a" onClick={() => navigate("/battles")}>
              <ListItemIcon>
                <SportsMmaIcon />
              </ListItemIcon>
              <ListItemText primary="Battles" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" onClick={() => navigate("/requests")}>
              <ListItemIcon>
                <MoveToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Requests" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />

      {!loading ? (
        <Box flex={1} p={2}>
          <Typography variant="h6" fontWeight={100} align="center">
            Top 5
          </Typography>
          <AvatarGroup max={5}>
            {topUsers &&
              topUsers.map((user) => {
                return (
                  <Tooltip title="Go to Profile" key={user.handle}>
                    <Avatar
                      alt={user.handle.toUpperCase()}
                      src={user.imageUrl}
                      onClick={() => navigate("/profile/" + user.handle)}
                    />
                  </Tooltip>
                );
              })}
          </AvatarGroup>
        </Box>
      ) : (
        <Skeleton variant="text" height={100} sx={{ m: 1 }} />
      )}
    </Box>
  );
};

export default Sidebar;
