import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAccount, updateAccount } from "../../redux/actions/userActions";
import { CLEAR_ERRORS } from "../../redux/types";

const ManageAccount = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [allowSensitive, setAllowSensitive] = useState("");
  const [allowRequests, setAllowRequests] = useState("");

  const errors = useSelector((state) => state.UI.errors);
  const loading = useSelector((state) => state.UI.loading);
  const messages = useSelector((state) => state.UI.messages);
  const credentials = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: CLEAR_ERRORS });
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      currentEmail: currentEmail,
      newEmail: newEmail,
      allowSensitive: allowSensitive,
      allowRequests: allowRequests,
      credentials: credentials,
    };
    dispatch(updateAccount(userData));
  };

  const deactivateAccount = (event) => {
    event.preventDefault();
    const userData = {
      currentEmail: currentEmail,
      newEmail: newEmail,
      allowSensitive: allowSensitive,
      allowRequests: allowRequests,
      credentials: credentials,
    };
    dispatch(deleteAccount(userData, navigate));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Manage Account
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="currentEmail"
            label="Current Email"
            name="currentEmail"
            autoComplete="currentEmail"
            autoFocus
            onChange={(e) => setCurrentEmail(e.target.value)}
            helperText={errors ? errors.currentEmail : ""}
            error={errors && errors.currentEmail ? true : false}
            value={currentEmail}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="newEmail"
            label="New Email Address"
            name="newEmail"
            autoComplete="newEmail"
            onChange={(e) => setNewEmail(e.target.value)}
            helperText={errors ? errors.newEmail : ""}
            error={errors && errors.newEmail ? true : false}
            value={newEmail}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              id="allowSensitive"
              name="allowSensitive"
              label="Allow Sensitive Content"
              checked={allowSensitive}
              onChange={(e) => setAllowSensitive(e.target.checked)}
            />
            <FormControlLabel
              id="allowRequests"
              name="allowRequests"
              control={<Switch />}
              label="Allow Requests"
              checked={allowRequests}
              onChange={(e) => setAllowRequests(e.target.checked)}
            />
          </FormGroup>
          {errors && errors.general && (
            <Typography variant="body2" color="error">
              {errors.general}
            </Typography>
          )}
          {messages && (
            <Typography variant="body2" color="success.main">
              {messages}
            </Typography>
          )}
          <Toolbar
            sx={{
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button type="submit" size="large" variant="contained">
              {loading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={deactivateAccount}
            >
              Deactivate Account
            </Button>
          </Toolbar>
        </Box>
      </Box>
    </Container>
  );
};

export default ManageAccount;
