import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LikeButton from "./LikeButton";

const Post = ({ battlePost }) => {
  const {
    direction,
    userHandle,
    createdAt,
    content,
    battlePostId,
    likeCount,
    userImage,
  } = battlePost;
  dayjs.extend(relativeTime);

  return (
    <Card
      sx={{
        margin: 1,
        mr: direction === "left" ? 15 : 0,
        ml: direction === "right" ? 15 : 0,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="user-image"
            src={userImage}
            alt={userHandle ? userHandle.toUpperCase() : ""}
          />
        }
        title={userHandle}
        subheader={dayjs(createdAt).fromNow()}
      />

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* Like btn */}
        <LikeButton itemId={battlePostId} type="battle" />
        <span>{likeCount} Likes</span>
      </CardActions>
    </Card>
  );
};

export default Post;
