import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBattleRequests } from "../../redux/actions/battleActions";
import RequestFeedItem from "./RequestFeedItem";

const RequestFeed = () => {
  const loading = useSelector((state) => state.UI.loading);
  const battleRequests = useSelector((state) => state.battles.battleRequests);
  const credentials = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getAllBattleRequests());
  }, [dispatch, credentials]);

  const showNext = (battleRequest) => {
    const createdAt = battleRequest.battleRequest.createdAt;
    if (battleRequests.length === 0) {
      console.log("All data fetched");
    } else {
      dispatch(getAllBattleRequests(createdAt, "next"));
      setPage(page + 1);
    }
  };

  const showPrevious = (battleRequest) => {
    const createdAt = battleRequest.battleRequest.createdAt;
    dispatch(getAllBattleRequests(createdAt, "prev"));
    setPage(page - 1);
  };
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    if (newValue === "prev") {
      showPrevious({ battleRequest: battleRequests[0] });
    } else if (newValue === "next") {
      showNext({
        battleRequest: battleRequests[battleRequests.length - 1],
      });
    } else {
      return;
    }
  };

  return (
    <Box p={{ xs: 0, md: 1 }}>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
        </Stack>
      ) : (
        <>
          {battleRequests.length === 0 ? (
            <Paper>
              <Typography variant="body2" align="center">
                Data not found, please try again later.
              </Typography>
            </Paper>
          ) : (
            battleRequests.map((battle) => {
              return (
                <RequestFeedItem
                  battle={battle}
                  key={battle.battleId}
                  direction={value}
                />
              );
            })
          )}
          {battleRequests.length > 0 && (
            <BottomNavigation value={value} onChange={handleChange}>
              {
                //show previous button only when we have items
                page === 1 ? (
                  ""
                ) : (
                  <Tooltip title="Prev">
                    <BottomNavigationAction
                      value="prev"
                      icon={<NavigateBeforeIcon fontSize="large" />}
                    />
                  </Tooltip>
                )
              }

              {
                //show next button only when we have items
                battleRequests.length < 5 ? (
                  ""
                ) : (
                  <Tooltip title="Next">
                    <BottomNavigationAction
                      value="next"
                      icon={<NavigateNextIcon fontSize="large" />}
                    />
                  </Tooltip>
                )
              }
            </BottomNavigation>
          )}
        </>
      )}
    </Box>
  );
};

export default RequestFeed;
