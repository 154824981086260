import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppBar, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import * as React from "react";
import { useSelector } from "react-redux";
import MyBattles from "../../pages/MyBattles";

const PublicProfileFeed = (props) => {
  const credentials = useSelector((state) => state.user.profile);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              align="center"
            >
              @{credentials.handle}'s Battles
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <MyBattles handle={credentials.handle} />
      <Zoom in={trigger}>
        <div onClick={handleClick}>
          <Fab
            color="secondary"
            size="small"
            aria-label="scroll back to top"
            sx={{ position: "absolute", bottom: 10, right: 25 }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Zoom>
    </>
  );
};
export default PublicProfileFeed;
