import {
  Avatar,
  Box,
  Button,
  Container,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import SecurityIcon from "@mui/icons-material/Security";

const Privacy = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <SecurityIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Privacy
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="currentPassword"
            label="Current Password"
            type="password"
            id="currentPassword"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmNewPassword"
            label="Confirm New Password"
            type="password"
            id="confirmNewPassword"
            autoComplete="confirm-new-password"
          />

          <Toolbar
            sx={{
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button type="submit" size="large" variant="contained">
              Submit
            </Button>
            <Button size="large" variant="contained" color="secondary">
              Deactivate Account
            </Button>
          </Toolbar>
        </Box>
      </Box>
    </Container>
  );
};

export default Privacy;
