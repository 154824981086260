import styled from "@emotion/styled";
import { Grid, Paper, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";

const ProfileSkeleton = (props) => {
  const UserBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
  });

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <Paper>
          <UserBox>
            <Stack direction="column" gap={1} mt={1} mb={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="20vh"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Skeleton
                  variant="circular"
                  height={100}
                  width={100}
                  sx={{ ml: 3, mb: 2 }}
                />
              </Box>
              <Skeleton variant="text" height={20} width={150} />
              <Skeleton variant="text" height={20} width={150} />
              <Skeleton variant="text" height={20} width={150} />

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                justifyContent="center"
                sx={{ mt: 2 }}
              >
                <Skeleton variant="text" height={20} />
                <Skeleton variant="text" height={20} />
              </Box>
            </Stack>
          </UserBox>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Skeleton variant="text" height={150} />
        <Skeleton variant="text" height={150} />
        <Skeleton variant="text" height={150} />
      </Grid>
    </Grid>
  );
};
export default ProfileSkeleton;
