import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { acceptBattleRequest } from "../../redux/actions/battleActions";
import RequestVertMenu from "./RequestVertMenu";

const RequestFeedItem = ({ battle, direction }) => {
  const authenticated = useSelector((state) => state.user.authenticated);
  const credentials = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();

  dayjs.extend(relativeTime);

  const acceptBattle = (event) => {
    event.preventDefault();
    const acceptBattleData = {
      battleId: battle.battleId,
      acceptedBy: credentials.handle,
      acceptorImage: credentials.imageUrl,
    };
    dispatch(
      acceptBattleRequest(acceptBattleData, battle.createdAt, direction)
    );
  };

  return (
    <Card sx={{ margin: 2 }}>
      <CardHeader
        avatar={<Avatar src={battle.userImage} alt={battle.userHandle} />}
        action={
          authenticated && (
            <IconButton aria-label="settings">
              <RequestVertMenu />
            </IconButton>
          )
        }
        title={battle.userHandle}
        subheader={dayjs(battle.createdAt).fromNow()}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {battle.content}
        </Typography>
      </CardContent>

      {authenticated ? (
        battle.userHandle !== credentials.handle ? (
          <CardActions disableSpacing style={{ justifyContent: "flex-end" }}>
            <Tooltip title="Accept">
              <IconButton aria-label="Accept" onClick={acceptBattle}>
                <CheckCircleIcon color="success" fontSize="large" />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Reject" onClick={rejectBattle}>
              <IconButton aria-label="Reject">
                <CancelIcon color="error" fontSize="large" />
              </IconButton>
            </Tooltip> */}
          </CardActions>
        ) : (
          <></>
        )
      ) : (
        <CardActions disableSpacing style={{ justifyContent: "flex-end" }}>
          <Tooltip title="Accept">
            <Link to="/login">
              <IconButton aria-label="Accept">
                <CheckCircleIcon color="success" fontSize="large" />
              </IconButton>
            </Link>
          </Tooltip>
          {/* <Tooltip title="Reject">
            <Link to="/login">
              <IconButton aria-label="Reject">
                <CancelIcon color="error" fontSize="large" />
              </IconButton>
            </Link>
          </Tooltip> */}
        </CardActions>
      )}
    </Card>
  );
};

export default RequestFeedItem;
