import { GET_ALL_TAGS } from "../types";

const initialState = {
  tags: [],
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TAGS:
      return {
        tags: action.payload,
      };

    default:
      return state;
  }
};

export default tagReducer;
