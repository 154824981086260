import { createTheme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Provider } from "react-redux";
import Main from "./components/layout/Main";
import store from "./redux/store";

const App = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <Provider store={store}>
        <Box bgcolor={"background.default"} color={"text.primary"}>
          <Main />
        </Box>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
