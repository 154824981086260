import CheckIcon from "@mui/icons-material/Check";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postBattle } from "../../redux/actions/battleActions";
import { CLEAR_ERRORS } from "../../redux/types";

const rules = [
  { title: "No pornography" },
  { title: "No violence or gory contents" },
  { title: "No hate speech and bullying" },
  { title: "No spamming and manipulation" },
  { title: "No deceptive content" },
  { title: "No illegal activities" },
  { title: "No impersonation" },
  { title: "No copyright infringement" },
];

const AddPost = () => {
  const [tag, setTag] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tcAccept, setTcAccept] = useState(false);
  const loading = useSelector((state) => state.UI.loading);
  const errors = useSelector((state) => state.UI.errors);
  const credentials = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();

    const postData = {
      title,
      content,
      tcAccept,
      tag,
      credentials,
    };
    dispatch(postBattle(postData));
  };

  const handleReset = (event) => {
    setTitle("");
    setContent("");
    setTag([]);
    setTcAccept(false);
    dispatch({ type: CLEAR_ERRORS });
  };
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={6} sm={12}>
            <Typography component="h1" variant="h5">
              Create Battle
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                autoFocus
                onChange={(e) => setTitle(e.target.value)}
                helperText={errors ? errors.title : ""}
                error={errors && errors.title ? true : false}
                value={title}
                inputProps={{ maxLength: 100 }}
                placeholder="Max 100 chars"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="content"
                label="Content"
                name="content"
                autoComplete="content"
                multiline
                rows={5}
                onChange={(e) => setContent(e.target.value)}
                helperText={errors ? errors.content : ""}
                error={errors && errors.content ? true : false}
                value={content}
                inputProps={{ maxLength: 500 }}
                placeholder="Max 500 chars"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={tcAccept}
                    onChange={(e) => setTcAccept(e.target.checked)}
                  />
                }
                label="* I accept Community Rules"
              />
              {errors && errors.general && (
                <Typography variant="body2" color="error">
                  {errors.general}
                </Typography>
              )}
              <Toolbar sx={{ justifyContent: "space-between" }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!tcAccept || !title || !content}
                >
                  {loading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <div />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Toolbar>
            </Box>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Paper variant="outlined" square sx={{ p: 1, width: "100%" }}>
                <List>
                  <ListItem
                    disablePadding
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="h6">Community Rules</Typography>
                  </ListItem>
                  <ListItem>
                    <Divider style={{ width: "100%" }} />
                  </ListItem>
                  {rules.map((rule) => (
                    <ListItem disablePadding key={rule.title}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={rule.title} />
                    </ListItem>
                  ))}
                  <ListItem>
                    <Divider style={{ width: "100%" }} />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <StarRateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Required fields" />
                  </ListItem>
                </List>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddPost;
