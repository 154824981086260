import {
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ERRORS,
  SET_MESSAGES,
  SNACKBAR_CLEAR,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  STOP_LOADING_UI,
} from "../types";

const initialState = {
  loading: false,
  errors: null,
  messages: null,
  successSnackbarOpen: false,
  errorSnackbarOpen: false,
  infoSnackbarOpen: false,
  successSnackbarMessage: null,
};
const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      };
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case SET_MESSAGES:
      return {
        ...state,
        loading: false,
        errors: null,
        messages: action.payload,
      };
    case SNACKBAR_SUCCESS:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
      };
    case SNACKBAR_ERROR:
      return {
        ...state,
        errorSnackbarOpen: true,
        errorSnackbarMessage: action.message,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };

    default:
      return state;
  }
};
export default uiReducer;
