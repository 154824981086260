import { DISLIKE_POST, LIKE_POST, SET_POSTS } from "../types";

const initialState = {
  posts: [],
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };

    case LIKE_POST:
    case DISLIKE_POST:
      let index = state.posts.findIndex(
        (post) => post.postId === action.payload.postId
      );
      let posts = [...state.posts];
      posts[index] = action.payload;
      return {
        ...state,
        posts,
      };

    default:
      return state;
  }
};

export default postReducer;
