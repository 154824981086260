import axios from "axios";
import {
  CLEAR_ERRORS,
  DISLIKE_POST,
  LIKE_POST,
  LOADING_UI,
  SET_ERRORS,
  SET_MESSAGES,
  SET_POSTS,
  STOP_LOADING_UI,
} from "../types";
import { showErrorSnackbar, showSuccessSnackbar } from "./snackbarActions";

export const addPost = (postData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/posts/add", postData)
    .then((res) => {
      dispatch({
        type: SET_MESSAGES,
        payload: res.data.message,
      });
      dispatch(showSuccessSnackbar("Success!"));
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
      dispatch(showErrorSnackbar("Server error. Please try again later."));
    });
};

export const getAllPosts = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/posts/all")
    .then((res) => {
      dispatch({
        type: SET_POSTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAllTrendingPosts = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/posts/trending")
    .then((res) => {
      dispatch({
        type: SET_POSTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPosts = (handle) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/posts", { params: { handle: handle } })
    .then((res) => {
      dispatch({
        type: SET_POSTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// Like a post
export const likePost = (postId) => (dispatch) => {
  axios
    .get(`/posts/${postId}/like`)
    .then((res) => {
      dispatch({
        type: LIKE_POST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// Dislike a post
export const dislikePost = (postId) => (dispatch) => {
  axios
    .get(`/posts/${postId}/dislike`)
    .then((res) => {
      dispatch({
        type: DISLIKE_POST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
