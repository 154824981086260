import { FavoriteBorder } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { likeBattlePost } from "../../redux/actions/battleActions";
import { dislikePost, likePost } from "../../redux/actions/postActions";
import MyButton from "./MyButton";

const LikeButton = ({ itemId, type }) => {
  const likes = useSelector((state) => state.user.likes);
  const authenticated = useSelector((state) => state.user.authenticated);
  const dispatch = useDispatch();

  const likedPost = () => {
    if (type === "battle") {
      if (likes && likes.find((like) => like.battlePostId === itemId)) {
        return true;
      } else {
        return false;
      }
    } else if (type === "post") {
      if (likes && likes.find((like) => like.postId === itemId)) {
        return true;
      } else {
        return false;
      }
    } else return false;
  };

  const likeAPost = () => {
    if (type === "battle") {
      dispatch(likeBattlePost(itemId));
    } else if (type === "post") {
      dispatch(likePost(itemId));
    }
  };

  const dislikeAPost = () => {
    if (type === "post") {
      dispatch(dislikePost(itemId));
    }
  };

  const likeButton = !authenticated ? (
    <Link to="/login">
      <MyButton tip="Like">
        <FavoriteBorder color="primary" />
      </MyButton>
    </Link>
  ) : likedPost() ? (
    <MyButton tip="Undo like" onClick={dislikeAPost}>
      <FavoriteIcon color="primary" />
    </MyButton>
  ) : (
    <MyButton tip="Like" onClick={likeAPost}>
      <FavoriteBorder color="primary" />
    </MyButton>
  );

  return likeButton;
};

export default LikeButton;
