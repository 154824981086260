import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabFeedItem from "../components/feed/TabFeedItem";
import { getBatlles } from "../redux/actions/battleActions";

const MyBattles = ({ handle }) => {
  const loading = useSelector((state) => state.UI.loading);
  const battles = useSelector((state) => state.battles.battles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatlles(handle));
  }, [dispatch, handle]);

  return (
    <Box p={{ xs: 0, md: 1 }}>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
        </Stack>
      ) : (
        <>
          {battles && battles.length > 0 ? (
            battles.map((battle) => {
              return <TabFeedItem battle={battle} key={battle.battleId} />;
            })
          ) : (
            <Typography align="center">No battles found</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default MyBattles;
