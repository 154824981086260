import axios from "axios";
import {
  CLEAR_ERRORS,
  LOADING_UI,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_ERRORS,
  SET_MESSAGES,
  SET_PROFILE,
  SET_TOP_USERS,
  SET_UNAUTHENTICATED,
  SET_USER,
  STOP_LOADING_UI,
} from "../types";

export const loginUser = (userData, navigate, fromPage) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData(navigate, fromPage));
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const signupUser = (newUserData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/signup", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      navigate("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = (navigate) => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  navigate("/");
  navigate(0);
};

export const getUserData = (navigate, fromPage) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/user")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
      navigate(fromPage);
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err && err.response ? err.response.data : null,
      });
    });
};

export const getProfile = (handle) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get(`/user/${handle}`)
    .then((res) => {
      dispatch({
        type: SET_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("get profile err", err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user", userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axios
    .post("/notifications", notificationIds)
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ,
      });
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/resetPassword", userData)
    .then((res) => {
      dispatch({
        type: SET_MESSAGES,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateAccount = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/updateAccount", userData)
    .then((res) => {
      dispatch({
        type: SET_MESSAGES,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteAccount = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/deleteAccount", userData)
    .then((res) => {
      dispatch({
        type: SET_MESSAGES,
        payload: res.data.message,
      });
      dispatch({ type: CLEAR_ERRORS });
      navigate("/");
      navigate(0);
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateProfile = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/updateProfile", userData)
    .then((res) => {
      dispatch(getUserData());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const changePassword = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/changePassword", userData)
    .then((res) => {
      dispatch({
        type: SET_MESSAGES,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTopUsers = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/users/top")
    .then((res) => {
      dispatch({
        type: SET_TOP_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response ? err.response.data : null,
      });
    });
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
