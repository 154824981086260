import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "../../redux/actions/snackbarActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ErrorSnackbar = () => {
  const dispatch = useDispatch();

  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    (state) => state.UI
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(clearSnackbar());
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={errorSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      action={action}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorSnackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
