import BlockIcon from "@mui/icons-material/Block";
import HideSourceIcon from "@mui/icons-material/HideSource";
import PanToolIcon from "@mui/icons-material/PanTool";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

const RequestVertMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {/* <Box>
        <Tooltip title="More Options">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      </Box> */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <HideSourceIcon fontSize="small" />
          </ListItemIcon>{" "}
          Hide this request
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <PanToolIcon fontSize="small" />
          </ListItemIcon>
          Hide future requests
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <BlockIcon fontSize="small" />
          </ListItemIcon>
          Block user
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default RequestVertMenu;
