import {
  ACCEPTED_BATTLE,
  LIKE_BATTLE_POST,
  POST_BATTLE,
  SET_BATTLE,
  SET_BATTLES,
  SET_BATTLE_POSTS,
  SET_BATTLE_REQUESTS,
  REJECTED_BATTLE,
} from "../types";

const initialState = {
  battles: [],
  battleRequests: [],
  battlePosts: [],
  battle: {},
};

const battleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BATTLES:
      let battles = action.payload;
      return {
        ...state,
        battles: battles,
      };

    case SET_BATTLE_REQUESTS:
      let battleRequest = action.payload;
      return {
        ...state,
        battleRequests: battleRequest,
        loading: false,
      };

    case ACCEPTED_BATTLE:
      return {
        ...state,
      };
    case REJECTED_BATTLE:
      return {
        ...state,
      };
    case SET_BATTLE_POSTS:
      return {
        ...state,
        battlePosts: action.payload,
      };
    case SET_BATTLE:
      return {
        ...state,
        battle: action.payload,
      };

    case POST_BATTLE:
      return {
        ...state,
        battles: [action.payload, ...state.battles],
      };

    case LIKE_BATTLE_POST:
      let index = state.battlePosts.findIndex(
        (battle) => battle.battlePostId === action.payload.battlePostId
      );
      let battlePosts = [...state.battlePosts];
      battlePosts[index] = action.payload;

      let battle = state.battle;
      if (battle.userHandle === action.payload.userHandle) {
        battle.userLikeCount = battle.userLikeCount + 1;
      } else if (battle.opponentHandle === action.payload.userHandle) {
        battle.opponentLikeCount = battle.opponentLikeCount + 1;
      }
      return {
        ...state,
        battlePosts,
        ...battle,
      };

    default:
      return state;
  }
};

export default battleReducer;
