// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_PROFILE = "SET_PROFILE";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const SET_TOP_USERS = "SET_TOP_USERS";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const SET_MESSAGES = "SET_MESSAGES";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const SET_TAB_INDEX = "SET_TAB_INDEX";
//Tag reducer types
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const GET_POSTS = "GET_POSTS";
export const SET_BATTLES = "SET_BATTLES";
export const SET_BATTLE_REQUESTS = "SET_BATTLE_REQUESTS";
export const SET_REJECTED_BATTLES = "SET_REJECTED_BATTLES";
export const ACCEPTED_BATTLE = "ACCEPTED_BATTLE";
export const REJECTED_BATTLE = "REJECTED_BATTLE";
export const SET_MY_BATTLES = "SET_MY_BATTLES";
export const SET_POSTS = "SET_POSTS";
export const SET_BATTLE_POSTS = "SET_BATTLE_POSTS";
export const SET_BATTLE = "SET_BATTLE";
export const POST_BATTLE = "POST_BATTLE";
//SNACKBAR
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";
//Like
export const LIKE_BATTLE_POST = "LIKE_BATTLE_POST";
export const DISLIKE_BATTLE_POST = "DISLIKE_BATTLE_POST";
export const LIKE_POST = "LIKE_POST";
export const DISLIKE_POST = "DISLIKE_POST";
