import {
  Avatar,
  Box,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import styled from "@emotion/styled";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProfile } from "../../redux/actions/userActions";
import ProfileSkeleton from "./ProfileSkeleton";
import PublicProfileFeed from "./PublicProfileFeed";

const PublicProfile = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const { userhandle } = useParams();

  const { name, handle, createdAt, imageUrl, bio } = useSelector(
    (state) => state.user.profile
  );

  const UserBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
  });

  useEffect(() => {
    if (userhandle) {
      dispatch(getProfile(userhandle));
    }
  }, [userhandle, dispatch]);

  const profileMarkup = !loading ? (
    handle !== undefined ? (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <Paper>
            <div id="back-to-top-anchor" />
            <UserBox>
              <Stack direction="column" gap={1} mt={1} mb={1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="20vh"
                  sx={{ pl: 2, display: "flex", justifyContent: "center" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="profile-image"
                        alt={handle.toUpperCase()}
                        src={imageUrl}
                        sx={{
                          minHeight: 150,
                          minWidth: 150,
                          fontSize: 100,
                        }}
                      />
                    }
                  />
                </Box>
                {name ? (
                  <Typography gutterBottom variant="h5" component="div">
                    {name}
                  </Typography>
                ) : (
                  <Typography gutterBottom component="div">
                    User has not added name yet
                  </Typography>
                )}

                <Typography gutterBottom variant="body1" component={Link}>
                  @{handle}
                </Typography>
                {bio ? (
                  <Typography gutterBottom variant="p" component="div">
                    {bio}
                  </Typography>
                ) : (
                  <Typography gutterBottom component="div">
                    User has not added bio yet
                  </Typography>
                )}
                <Divider />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <TodayIcon />
                  <Typography variant="span" sx={{ ml: 2 }}>
                    Joined {dayjs(createdAt).format("MMM YYYY")}
                  </Typography>
                </Box>
              </Stack>
            </UserBox>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper>
            <PublicProfileFeed />
          </Paper>
        </Grid>
      </Grid>
    ) : (
      <Paper>
        <Typography variant="body2" align="center">
          No profile found, please login again
        </Typography>
      </Paper>
    )
  ) : (
    <ProfileSkeleton />
  );

  return profileMarkup;
};

export default PublicProfile;
