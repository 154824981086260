import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const TabFeedItem = ({ battle }) => {
  const navigate = useNavigate();
  const UserBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
  });

  const progressChipMarkup = (battle, handle) => {
    if (battle.winner === "") {
      if (battle.userLikeCount === battle.opponentLikeCount) {
        return null;
      } else if (
        battle.userLikeCount > battle.opponentLikeCount &&
        battle.userHandle === handle
      ) {
        return <Chip label="Winning" color="success" />;
      } else if (
        battle.userLikeCount < battle.opponentLikeCount &&
        battle.opponentHandle === handle
      ) {
        return <Chip label="Winning" color="success" />;
      } else {
        return <Chip label="Losing" color="warning" />;
      }
    } else {
      if (battle.userLikeCount === battle.opponentLikeCount) {
        return null;
      } else if (
        battle.userHandle === handle &&
        battle.userHandle === battle.winner
      ) {
        return <Chip label="Winner" color="success" />;
      } else if (
        battle.opponentHandle === handle &&
        battle.opponentHandle === battle.winner
      ) {
        return <Chip label="Winner" color="success" />;
      } else {
        return <Chip label="Loser" color="warning" />;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
      }}
    >
      <Paper
        elevation={1}
        component={Stack}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4} md={4} lg={4} style={{ textAlign: "center" }}>
            <UserBox>
              <Stack direction="column" gap={1} mt={2} mb={2}>
                <Tooltip title="Go to Profile">
                  <Avatar
                    src={battle.userImage}
                    sx={{ width: 100, height: 100, fontSize: 50 }}
                    alt={
                      battle.userHandle ? battle.userHandle.toUpperCase() : ""
                    }
                    onClick={() => navigate("/profile/" + battle.userHandle)}
                  />
                </Tooltip>
                <Typography>{battle.userHandle}</Typography>
                {progressChipMarkup(battle, battle.userHandle)}
              </Stack>
            </UserBox>
          </Grid>
          <Grid item xs={2} md={2} lg={2} style={{ textAlign: "center" }}>
            <Typography variant="h3" color="secondary">
              VS
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4} style={{ textAlign: "center" }}>
            <UserBox>
              <Stack
                direction="column"
                gap={1}
                mt={2}
                mb={2}
                alignItems="center"
              >
                <Tooltip title="Go to Profile">
                  <Avatar
                    src={battle.acceptorImage}
                    sx={{ width: 100, height: 100, fontSize: 50 }}
                    alt={
                      battle.opponentHandle
                        ? battle.opponentHandle.toUpperCase()
                        : ""
                    }
                    onClick={() =>
                      navigate("/profile/" + battle.opponentHandle)
                    }
                  />
                </Tooltip>
                <Typography>{battle.opponentHandle}</Typography>
                {progressChipMarkup(battle, battle.opponentHandle)}
              </Stack>
            </UserBox>
          </Grid>
          <Grid item xs={2} md={2} lg={2} style={{ textAlign: "center" }}>
            <Tooltip title="Go to Battle">
              <IconButton
                aria-label="Go to Battle"
                onClick={() => navigate("/battles/" + battle.battleId)}
              >
                <NavigateNextIcon color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default TabFeedItem;
