import { Grid } from "@mui/material";
import * as React from "react";
import PostFeed from "./PostFeed";
const PostFeedHome = () => {
  return (
    <Grid container spacing={2} direction="row" maxWidth="lg">
      <Grid item md={12} width="100%">
        <PostFeed />
      </Grid>
    </Grid>
  );
};
export default PostFeedHome;
