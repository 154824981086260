import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PasswordIcon from "@mui/icons-material/Password";
import { useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Zoom from "@mui/material/Zoom";
import * as React from "react";
import ChangePassword from "../components/account/ChangePassword";
import ManageAccount from "../components/account/ManageAccount";
import Privacy from "../components/account/Privacy";
import TabPanel from "../components/feed/TabPanel";
const Account = (props) => {
  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        position: "relative",
        width: "100%",
      }}
    >
      <div id="back-to-top-anchor" />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab
            label="Account"
            icon={<ManageAccountsIcon />}
            {...allyProps(0)}
          />
          <Tab label="Password" icon={<PasswordIcon />} {...allyProps(1)} />
          {/* <Tab
            label="Privacy"
            icon={<AdminPanelSettingsIcon />}
            {...allyProps(2)}
          /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ManageAccount />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePassword />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Privacy />
      </TabPanel>

      <Zoom in={trigger}>
        <div onClick={handleClick}>
          <Fab
            color="secondary"
            size="small"
            aria-label="scroll back to top"
            sx={{ position: "absolute", bottom: 100, right: 25 }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Zoom>
    </Box>
  );
};
export default Account;
