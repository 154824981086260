import LoginIcon from "@mui/icons-material/Login";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../redux/actions/userActions";
import { CLEAR_ERRORS } from "../../redux/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const errors = useSelector((state) => state.UI.errors);
  const loading = useSelector((state) => state.UI.loading);
  const messages = useSelector((state) => state.UI.messages);
  const credentials = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_ERRORS });
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      currentPassword,
      newPassword,
      confirmNewPassword,
      credentials,
    };
    dispatch(changePassword(userData));
  };

  const handleClear = (event) => {
    event.preventDefault();
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    dispatch({ type: CLEAR_ERRORS });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="currentPassword"
            label="Current Password"
            type={showPassword ? "text" : "password"}
            id="currentPassword"
            autoComplete="current-password"
            autoFocus
            onChange={(e) => setCurrentPassword(e.target.value)}
            helperText={errors ? errors.currentPassword : ""}
            error={errors && errors.currentPassword ? true : false}
            value={currentPassword}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type={showPassword ? "text" : "password"}
            id="newPassword"
            autoComplete="new-password"
            onChange={(e) => setNewPassword(e.target.value)}
            helperText={errors ? errors.newPassword : ""}
            error={errors && errors.newPassword ? true : false}
            value={newPassword}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmNewPassword"
            label="Confirm New Password"
            type={showPassword ? "text" : "password"}
            id="confirmNewPassword"
            autoComplete="confirm-new-password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            helperText={errors ? errors.confirmNewPassword : ""}
            error={errors && errors.confirmNewPassword ? true : false}
            value={confirmNewPassword}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors && errors.general && (
            <Typography variant="body2" color="error">
              {errors.general}
            </Typography>
          )}
          {messages && (
            <Typography variant="body2" color="success.main">
              {messages}
            </Typography>
          )}

          <Toolbar
            sx={{
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Button type="submit" size="large" variant="contained">
              {loading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={handleClear}
            >
              Clear
            </Button>
          </Toolbar>
        </Box>
      </Box>
    </Container>
  );
};

export default ChangePassword;
