import { Grid } from "@mui/material";
import * as React from "react";
import TabFeed from "./TabFeed";
const TabFeedHome = () => {
  return (
    <Grid container spacing={2} direction="row" maxWidth="lg">
      <Grid item md={12} width="100%">
        <TabFeed />
      </Grid>
    </Grid>
  );
};
export default TabFeedHome;
