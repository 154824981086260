import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import styled from "@emotion/styled";
import TodayIcon from "@mui/icons-material/Today";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditProfile from "./EditProfile";
import PublicProfileFeed from "./PublicProfileFeed";
import ProfileSkeleton from "./ProfileSkeleton";
import { getProfile } from "../../redux/actions/userActions";

const Profile = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const authenticated = useSelector((state) => state.user.authenticated);

  const { handle, name, createdAt, imageUrl, bio } = useSelector(
    (state) => state.user.credentials
  );

  const UserBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
  });

  useEffect(() => {
    if (handle) {
      dispatch(getProfile(handle));
    }
  }, [handle, dispatch]);

  const profileMarkup = !loading ? (
    authenticated ? (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
          <Paper>
            <div id="back-to-top-anchor" />
            <UserBox>
              <Stack direction="column" gap={1} mt={1} mb={1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="20vh"
                  sx={{ pl: 2, display: "flex", justifyContent: "center" }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="profile-image"
                        src={imageUrl}
                        sx={{
                          minHeight: 150,
                          minWidth: 150,
                        }}
                      />
                    }
                  />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  {name}
                </Typography>
                <Typography gutterBottom variant="body1" component={Link}>
                  @{handle}
                </Typography>
                <Typography
                  variant="p"
                  color="text.secondary"
                  sx={{ mb: 2, mt: 2 }}
                >
                  {bio}
                </Typography>
                <Divider />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <TodayIcon />
                  <Typography variant="span" sx={{ ml: 2 }}>
                    Joined {dayjs(createdAt).format("MMM YYYY")}
                  </Typography>
                </Box>
                <EditProfile />
              </Stack>
            </UserBox>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper>
            <PublicProfileFeed />
          </Paper>
        </Grid>
      </Grid>
    ) : (
      <Paper>
        <Typography variant="body2" align="center">
          No profile found, please login again
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/signup"
        >
          Sign Up
        </Button>
      </Paper>
    )
  ) : (
    <ProfileSkeleton />
  );

  return profileMarkup;
};

export default Profile;
