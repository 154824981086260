import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const FAQ = () => {
  return (
    <Box>
      <Typography variant="h3" align="center">
        FAQ
      </Typography>

      <Divider />
      <Typography variant="h4" paragraph sx={{ mt: 2 }}>
        How do I create new Battle?
      </Typography>
      <Typography paragraph>
        Click on Post button on top right corner. Add a catchy title and
        description. Accept community terms and submit. After submit, your
        battle request will show up on requests page for others to accept.
      </Typography>

      <Typography variant="h4">Can the battle be edited or deleted?</Typography>
      <Typography paragraph>
        Not at this time. Hence, be creative and follow community rules before
        creating a battle post.
      </Typography>

      <Typography variant="h4">How are the winners decided?</Typography>
      <Typography paragraph>
        Winners are decided on the most likes they get on their posts. So be
        sure to invite and let your friends know.
      </Typography>

      <Typography variant="h4">When are the winners declared?</Typography>
      <Typography paragraph>
        Every 6 hours, winners are declared based on the most likes received.
        This duration may change without any warning in future.
      </Typography>

      <Typography variant="h4">Can I create multiple battles?</Typography>
      <Typography paragraph>There is no limit at the moment.</Typography>

      <Typography variant="h4">
        How much time do I have to complete the battle?
      </Typography>
      <Typography paragraph>
        Once the battle is created, both participants have 24 hours to complete.
      </Typography>

      <Typography variant="h4">How do declare top 5?</Typography>
      <Typography paragraph>
        Every 24 hours, we count all the likes received on each battle post, and
        users with the most total likes become top 5 users.
      </Typography>

      <Typography variant="h4">What do the winners get?</Typography>
      <Typography paragraph>
        Exciting prizes are coming soon for top 5 users, daily winners and top
        contributors.
      </Typography>

      <Typography variant="h4">What are community rules?</Typography>
      <Typography paragraph>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No pornography</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No violence or gory contents</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No hate speech and bullying</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No spamming and manipulation</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No deceptive content</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No illegal activities</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No impersonation</ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <ListItemText>No copyright infringement</ListItemText>
          </ListItem>
        </List>
      </Typography>
    </Box>
  );
};

export default FAQ;
