import { Grid, MenuItem, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Paper
      sx={{
        marginTop: "calc(10% + 60px)",
        width: "100%",
        bottom: 0,
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid>
          <Link to="privacy" style={{ textDecoration: "none" }}>
            <MenuItem>Privacy Policy</MenuItem>
          </Link>
        </Grid>
        <Grid>
          <Link to="tnc" style={{ textDecoration: "none" }}>
            <MenuItem>Terms</MenuItem>
          </Link>
        </Grid>
        <Grid>
          <Link to="faq" style={{ textDecoration: "none" }}>
            <MenuItem>FAQ</MenuItem>
          </Link>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}
      >
        <Typography variant="caption">
          Copyright © {new Date().getFullYear()}. jokesbattle.com
        </Typography>
      </Grid>
    </Paper>
  );
};

export default Footer;
