import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addBattlePost,
  getBatllesById,
  getBattlePosts,
} from "../../redux/actions/battleActions";
import Post from "../feed/Post";

const Battle = () => {
  const [battlePostContent, setBattlePostContent] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.UI.loading);
  const battlePosts = useSelector((state) => state.battles.battlePosts);
  const credentials = useSelector((state) => state.user.credentials);
  const battle = useSelector((state) => state.battles.battle);
  const errors = useSelector((state) => state.UI.errors);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getBattlePosts(id));
    dispatch(getBatllesById(id));
    setBattlePostContent("");
  }, [dispatch, id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newPostData = {
      id,
      content: battlePostContent,
      direction: getDirection(battlePosts),
      credentials,
    };
    dispatch(addBattlePost(newPostData, id));
  };

  const refreshPage = (event) => {
    dispatch(getBattlePosts(id));
    dispatch(getBatllesById(id));
    setBattlePostContent("");
  };

  const getDirection = (battlePosts) => {
    if (battlePosts === undefined) {
      return "left";
    } else if (battlePosts.length === 0) {
      return "left";
    } else if (battlePosts.length > 0) {
      if (battlePosts[battlePosts.length - 1].direction === "right") {
        return "left";
      } else {
        return "right";
      }
    }
  };

  const chatBubbles = battlePosts.map((battlePost, i = 0) => (
    <Post key={i++} battlePost={battlePost} />
  ));

  const checkInputCondition = (battlePosts, battle) => {
    if (
      getDirection(battlePosts) === "left" &&
      battle.userHandle === credentials.handle
    ) {
      return true;
    } else if (
      getDirection(battlePosts) === "right" &&
      battle.opponentHandle === credentials.handle
    ) {
      return true;
    } else {
      return false;
    }
  };

  const progressChipMarkup = (battle, handle) => {
    if (battle.winner === "") {
      if (battle.userLikeCount === battle.opponentLikeCount) {
        return null;
      } else if (
        battle.userLikeCount > battle.opponentLikeCount &&
        battle.userHandle === handle
      ) {
        return <Chip label="W" color="success" />;
      } else if (
        battle.userLikeCount < battle.opponentLikeCount &&
        battle.opponentHandle === handle
      ) {
        return <Chip label="W" color="success" />;
      } else {
        return <Chip label="L" color="warning" />;
      }
    } else {
      if (battle.userLikeCount === battle.opponentLikeCount) {
        return null;
      } else if (
        battle.userHandle === handle &&
        battle.userHandle === battle.winner
      ) {
        return <Chip label="W" color="success" />;
      } else if (
        battle.opponentHandle === handle &&
        battle.opponentHandle === battle.winner
      ) {
        return <Chip label="W" color="success" />;
      } else {
        return <Chip label="L" color="warning" />;
      }
    }
  };

  return (
    <>
      {!loading ? (
        <Paper
          style={{
            height: "100%",
          }}
          variant="outlined"
        >
          <AppBar position="sticky" color="default">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={1} md={1} lg={1} style={{ textAlign: "center" }}>
                <Tooltip title="Back">
                  <IconButton aria-label="back" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4} md={4} lg={4} style={{ textAlign: "center" }}>
                <Stack
                  direction="row"
                  gap={1}
                  mt={2}
                  mb={2}
                  alignItems="center"
                >
                  <Tooltip title="Go to Profile">
                    <Avatar
                      src={battle.userImage}
                      sx={{ width: 30, height: 30, fontSize: 20 }}
                      alt={
                        battle.userHandle ? battle.userHandle.toUpperCase() : ""
                      }
                      onClick={() => navigate("/profile/" + battle.userHandle)}
                    />
                  </Tooltip>
                  <Typography>{battle.userHandle}</Typography>
                  {progressChipMarkup(battle, battle.userHandle)}
                  <FavoriteIcon />
                  <Typography>{battle.userLikeCount}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={2} md={2} lg={2} style={{ textAlign: "center" }}>
                <Typography variant="subtitle1" color="secondary">
                  VS
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4} style={{ textAlign: "center" }}>
                <Stack
                  direction="row"
                  gap={1}
                  mt={2}
                  mb={2}
                  alignItems="center"
                >
                  <Tooltip title="Go to Profile">
                    <Avatar
                      src={battle.acceptorImage}
                      sx={{ width: 30, height: 30, fontSize: 20 }}
                      alt={
                        battle.opponentHandle
                          ? battle.opponentHandle.toUpperCase()
                          : ""
                      }
                      onClick={() =>
                        navigate("/profile/" + battle.opponentHandle)
                      }
                    />
                  </Tooltip>
                  <Typography>{battle.opponentHandle}</Typography>
                  {progressChipMarkup(battle, battle.opponentHandle)}
                  <FavoriteIcon />
                  <Typography>{battle.opponentLikeCount}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={1} md={1} lg={1} style={{ textAlign: "center" }}>
                <Tooltip title="Refresh">
                  <IconButton aria-label="refresh" onClick={refreshPage}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </AppBar>
          {chatBubbles}
          {battlePosts.length < 6 && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                margin: 1,
                mr: getDirection(battlePosts) === "left" ? 15 : 0,
                ml: getDirection(battlePosts) === "right" ? 15 : 0,
              }}
            >
              {loading ? (
                <>
                  <Typography>Loading data. Please wait..</Typography>
                </>
              ) : checkInputCondition(battlePosts, battle) ? (
                <>
                  <TextField
                    margin="normal"
                    fullWidth
                    name="battlePostContent"
                    label="Post your reply.."
                    id="battlePostContent"
                    multiline
                    rows={3}
                    onChange={(e) => setBattlePostContent(e.target.value)}
                    helperText={errors ? errors.battlePostContent : ""}
                    error={errors && errors.battlePostContent ? true : false}
                    value={battlePostContent}
                    inputProps={{ maxLength: 500 }}
                    placeholder="Max 500 chars"
                  />
                  <Toolbar sx={{ justifyContent: "flex-start" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={battlePostContent.length === 0}
                    >
                      Submit
                    </Button>
                    <div />
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ ml: 2 }}
                      onClick={() => setBattlePostContent("")}
                    >
                      Reset
                    </Button>
                  </Toolbar>
                </>
              ) : (
                <>
                  <Card>
                    <CardContent>
                      <Typography>Waiting for opponent..</Typography>
                    </CardContent>
                  </Card>
                </>
              )}
            </Box>
          )}
        </Paper>
      ) : (
        <Typography>Loading..</Typography>
      )}
    </>
  );
};

export default Battle;
