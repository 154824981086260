import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../redux/actions/userActions";
import { CLEAR_ERRORS } from "../redux/types";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const errors = useSelector((state) => state.UI.errors);
  const loading = useSelector((state) => state.UI.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const captchaRef = useRef(null);

  const verifyEmail = location.state?.verifyEmail;
  const fromPage = location.state?.from || "/";

  useEffect(() => {
    dispatch({ type: CLEAR_ERRORS });
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    replaceHistory();
    const token = captchaRef.current.getValue();
    const userData = {
      email: email,
      password: password,
      token: token,
    };
    dispatch(loginUser(userData, navigate, fromPage));
    captchaRef.current.reset();
  };
  const replaceHistory = (e) => {
    if (e) {
      e.preventDefault();
      delete e.returnValue;
    }

    navigate(location.pathname, { replace: true });
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            helperText={errors ? errors.email : ""}
            error={errors && errors.email ? true : false}
            value={email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            helperText={errors ? errors.password : ""}
            error={errors && errors.password ? true : false}
            value={password}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {errors && errors.general && (
            <Typography variant="body2" color="error">
              {errors.general}
            </Typography>
          )}
          <ReCAPTCHA
            sitekey="6LcTUBsnAAAAAGLzWd_LjgXX_SZ9cngjLD9YcI71"
            ref={captchaRef}
            theme="dark"
          />
          {verifyEmail && (
            <Typography variant="body2" color="error">
              {verifyEmail}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgot" variant="body2" component="button">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
