import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabFeedItem from "../components/feed/TabFeedItem";
import { getAllBattles } from "../redux/actions/battleActions";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Battles = ({ category }) => {
  const loading = useSelector((state) => state.UI.loading);
  const battles = useSelector((state) => state.battles.battles);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getAllBattles(category));
  }, [dispatch, category]);

  const showNext = (battle) => {
    const createdAt = battle.battle.createdAt;
    if (battles.length === 0) {
      console.log("All data fetched");
    } else {
      dispatch(getAllBattles(category, createdAt, "next"));
      setPage(page + 1);
    }
  };

  const showPrevious = (battle) => {
    const createdAt = battle.battle.createdAt;
    dispatch(getAllBattles(category, createdAt, "prev"));
    setPage(page - 1);
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    if (newValue === "prev") {
      showPrevious({ battle: battles[0] });
    } else if (newValue === "next") {
      showNext({
        battle: battles[battles.length - 1],
      });
    } else {
      return;
    }
  };

  return (
    <Box p={{ xs: 0, md: 1 }}>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
        </Stack>
      ) : (
        <>
          {battles.length === 0 ? (
            <Paper>
              <Typography variant="body2" align="center">
                Data not found, please try again later.
              </Typography>
            </Paper>
          ) : (
            battles.map((battle) => {
              return <TabFeedItem battle={battle} key={battle.battleId} />;
            })
          )}
          {battles.length > 0 && (
            <BottomNavigation value={value} onChange={handleChange}>
              {
                //show previous button only when we have items
                page === 1 ? (
                  ""
                ) : (
                  <Tooltip title="Prev">
                    <BottomNavigationAction
                      value="prev"
                      icon={<NavigateBeforeIcon fontSize="large" />}
                    />
                  </Tooltip>
                )
              }

              {
                //show next button only when we have items
                battles.length < 5 ? (
                  ""
                ) : (
                  <Tooltip title="Next">
                    <BottomNavigationAction
                      value="next"
                      icon={<NavigateNextIcon fontSize="large" />}
                    />
                  </Tooltip>
                )
              }
            </BottomNavigation>
          )}
        </>
      )}
    </Box>
  );
};

export default Battles;
