import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LikeButton from "../feed/LikeButton";
const PostItem = ({ post }) => {
  dayjs.extend(relativeTime);
  const { userImage, createdBy, createdAt, postId, likeCount, content } = post;
  return (
    <Box
      sx={{
        display: "flex",
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
      }}
    >
      <Card>
        <CardHeader
          avatar={
            <Avatar src={userImage} aria-label="user-image">
              R
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={createdBy}
          subheader={dayjs(createdAt).fromNow()}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {content}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {/* Like btn */}
          <LikeButton itemId={postId} type="post" />
          <span>{likeCount} Likes</span>
        </CardActions>
      </Card>
    </Box>
  );
};

export default PostItem;
