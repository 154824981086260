import jwtDecode from "jwt-decode";
import React from "react";
import Home from "../../pages/Home";
import Footer from "./Footer";

import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import { getUserData, logoutUser } from "../../redux/actions/userActions";
import store from "../../redux/store";
import { SET_AUTHENTICATED } from "../../redux/types";
import ErrorSnackbar from "../util/ErrorSnackbar";
import SuccessSnackbar from "../util/SuccessSnackbar";

const Main = () => {
  const token = localStorage.FBIdToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    } else {
      store.dispatch({ type: SET_AUTHENTICATED });
      axios.defaults.headers.common["Authorization"] = token;
      store.dispatch(getUserData());
    }
  }

  return (
    <Router>
      <Home />
      <Footer />
      <SuccessSnackbar />
      <ErrorSnackbar />
    </Router>
  );
};

export default Main;
