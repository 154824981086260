import axios from "axios";
import {
  ACCEPTED_BATTLE,
  CLEAR_ERRORS,
  LIKE_BATTLE_POST,
  LOADING_UI,
  POST_BATTLE,
  REJECTED_BATTLE,
  SET_BATTLE,
  SET_BATTLES,
  SET_BATTLE_POSTS,
  SET_BATTLE_REQUESTS,
  SET_ERRORS,
  STOP_LOADING_UI,
} from "../types";
import { showSuccessSnackbar } from "./snackbarActions";

export const getAllBattles = (category, createdAt, direction) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/battles/category/${category}`, {
      params: {
        createdAt: createdAt,
        direction: direction,
      },
    })
    .then((res) => {
      dispatch({
        type: SET_BATTLES,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err && err.response ? err.response.data : null,
      });
    });
};

export const getBatlles = (handle) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/profile/${handle}/battles`)
    .then((res) => {
      dispatch({
        type: SET_BATTLE_POSTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAllBattleRequests = (createdAt, direction) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/battles/requests/category/fresh", {
      params: {
        createdAt: createdAt,
        direction: direction,
      },
    })
    .then((res) => {
      dispatch({
        type: SET_BATTLE_REQUESTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err && err.response ? err.response.data : null,
      });
    });
};

export const getBattleRequests = (handle) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/battles", { params: { handle: handle } })
    .then((res) => {
      dispatch({
        type: SET_BATTLES,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const acceptBattleRequest =
  (acceptBattleData, createdAt, direction) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post("/battles/accept", acceptBattleData)
      .then((res) => {
        dispatch({
          type: ACCEPTED_BATTLE,
          payload: res.data,
        });
        dispatch(getAllBattles());
        dispatch(getAllBattleRequests(createdAt, direction));
        dispatch(showSuccessSnackbar("Success!"));
      })
      .catch((err) => {
        dispatch({ type: STOP_LOADING_UI });
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const rejectBattleRequest =
  (rejectBattleData, createdAt, direction) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post("/battles/reject", rejectBattleData)
      .then((res) => {
        dispatch({
          type: REJECTED_BATTLE,
          payload: res.data,
        });
        dispatch(getAllBattleRequests(createdAt, direction));
        dispatch(showSuccessSnackbar("Success!"));
      })
      .catch((err) => {
        dispatch({ type: STOP_LOADING_UI });
        dispatch({
          type: SET_ERRORS,
          payload: err && err.response ? err.response.data : null,
        });
      });
  };

export const getBattlePosts = (battleId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/battles/${battleId}/posts`)
    .then((res) => {
      dispatch({
        type: SET_BATTLE_POSTS,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const addBattlePost = (newBattlePost, battleId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/battles/posts/add", newBattlePost)
    .then((res) => {
      dispatch(getBattlePosts(battleId));
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getBatllesById = (battleId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/battles/${battleId}`)
    .then((res) => {
      dispatch({
        type: SET_BATTLE,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Like a battle post
export const likeBattlePost = (battlePostId) => (dispatch) => {
  axios
    .get(`/battlePosts/${battlePostId}/like`)
    .then((res) => {
      dispatch({
        type: LIKE_BATTLE_POST,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// Post a battle
export const postBattle = (newBattle) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/battle", newBattle)
    .then((res) => {
      dispatch({
        type: POST_BATTLE,
        payload: res.data,
      });
      dispatch(showSuccessSnackbar("Success!"));
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
