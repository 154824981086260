import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Paper,
  styled,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, uploadImage } from "../../redux/actions/userActions";
import { CLEAR_ERRORS } from "../../redux/types";

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "scroll",
  marginTop: "5vh",
});

const EditProfile = () => {
  const dispatch = useDispatch();
  const credentials = useSelector((state) => state.user.credentials);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const errors = useSelector((state) => state.UI.errors);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: CLEAR_ERRORS });
    if (credentials.name) {
      setName(credentials.name);
    } else {
      setName("");
    }
    if (credentials.bio) {
      setBio(credentials.bio);
    } else {
      setBio("");
    }
  }, [dispatch, credentials]);

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image, image.name);
    dispatch(uploadImage(formData));
  };

  const handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      name,
      bio,
      credentials,
    };
    dispatch(updateProfile(userData));
  };
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
        sx={{ m: 2 }}
      >
        <Button
          tip="Edit Details"
          onClick={() => setOpen(true)}
          variant="contained"
        >
          Edit
        </Button>
      </Box>
      <StyledModal
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="modal-edit-profile"
      >
        <Paper>
          <Container component="main" maxWidth="xs">
            <Grid container spacing={2} paddingTop={5}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={<EditIcon />}
                  onClick={handleEditPicture}
                >
                  <input
                    type="file"
                    id="imageInput"
                    hidden="hidden"
                    onChange={handleImageChange}
                  />
                  <Avatar
                    sx={{
                      minHeight: 200,
                      minWidth: 200,
                    }}
                    aria-label="edit-profile"
                    src={credentials.imageUrl}
                  />
                </Badge>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography component="h1" variant="h5" align="center">
                  Edit Profile
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    onChange={(e) => setName(e.target.value)}
                    helperText={errors ? errors.name : ""}
                    error={errors && errors.name ? true : false}
                    value={name}
                    inputProps={{ maxLength: 100 }}
                    placeholder="Max 100 chars"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    name="bio"
                    label="Bio"
                    id="bio"
                    multiline
                    rows={3}
                    onChange={(e) => setBio(e.target.value)}
                    helperText={errors ? errors.bio : ""}
                    error={errors && errors.bio ? true : false}
                    value={bio}
                    inputProps={{ maxLength: 500 }}
                    placeholder="Max 500 chars"
                  />
                  {errors && errors.general && (
                    <Typography variant="body2" color="error">
                      {errors.general}
                    </Typography>
                  )}
                  <Toolbar sx={{ justifyContent: "space-between", mt: 3 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={
                        name == null ||
                        name.length === 0 ||
                        bio == null ||
                        bio.length === 0
                      }
                    >
                      Submit
                    </Button>
                    <div />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Toolbar>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </StyledModal>
    </>
  );
};

export default EditProfile;
