import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import { Button, Drawer, styled, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/yjb_logo.png";
import AccountMenu from "./AccountMenu";
import Notifications from "./Notifications";
import Sidebar from "./Sidebar";

const drawerWidth = 240;

const Navbar = (props) => {
  const authenticated = useSelector((state) => state.user.authenticated);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
  });
  const Icons = styled(Box)(({ theme }) => ({
    display: "none",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <StyledToolbar>
          <Box display="flex" flexGrow={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton aria-label="home" onClick={() => navigate("/")}>
              <PregnantWomanIcon
                sx={{ display: { xs: "block", sm: "none" }, mr: 1 }}
                fontSize="large"
                color="#f7ff66"
              />
            </IconButton>
            <Box
              component="img"
              sx={{
                height: 50,
                display: { xs: "none", sm: "block" },
                p: 1,
                m: 0,
              }}
              alt="jokesbattle"
              src={Logo}
              onClick={() => navigate("/")}
            />
            <Typography
              fontSize="2rem"
              sx={{ display: { xs: "none", sm: "block" }, p: 1 }}
              onClick={() => navigate("/")}
            >
              Jokes Battle
            </Typography>
          </Box>

          <Icons>
            <Tooltip title="Home">
              <IconButton aria-label="home" onClick={() => navigate("/")}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
            {authenticated && <Notifications />}

            <Button
              startIcon={<AddCircleIcon />}
              variant="contained"
              onClick={() => navigate("/posts/add")}
            >
              Post
            </Button>
          </Icons>
          {authenticated ? (
            <AccountMenu />
          ) : (
            <Button
              startIcon={<LoginIcon />}
              variant="contained"
              onClick={() => navigate("/login")}
              sx={{ ml: 2 }}
            >
              Login
            </Button>
          )}
        </StyledToolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Sidebar />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>
    </Box>
  );
};

export default Navbar;
