import { Box, Skeleton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatlles } from "../../redux/actions/battleActions";
import {
  getAllPosts,
  getAllTrendingPosts,
} from "../../redux/actions/postActions";
import PostItem from "./PostItem";

const Posts = ({ handle, type }) => {
  const loading = useSelector((state) => state.UI.loading);
  const posts = useSelector((state) => state.posts.posts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (handle) {
      dispatch(getBatlles(handle));
    } else {
      if (type === "trending" || type === "top") {
        dispatch(getAllTrendingPosts());
      } else {
        dispatch(getAllPosts());
      }
    }
  }, [dispatch, handle, type]);

  return (
    <Box p={{ xs: 0, md: 1 }}>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
          <Skeleton variant="text" height={100} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="text" height={20} />
          <Skeleton variant="rectangular" height={300} />
        </Stack>
      ) : (
        <>
          {posts &&
            posts.map((post) => {
              return <PostItem post={post} key={post.postId} />;
            })}
        </>
      )}
    </Box>
  );
};

export default Posts;
